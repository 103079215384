<template>
  <SearchBar @new-search="handleNewSearch" />
  <TableEventResults :data="searchResults" :isLoading="isLoading" />
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import TableEventResults from "@/components/TableEventResults";
import router from "@/router";
import { watch } from "@vue/runtime-core";
import { ref } from "vue";
import { useRoute } from "vue-router";
import SeatGeekService from "@/services/SeatGeekService";
import stringToSlug from "@/components/functions/string_to_slug.js";

export default {
  name: "EventContainer",
  components: {
    SearchBar,
    TableEventResults,
  },
  setup() {
    const route = useRoute();
    const isLoading = ref(false);
    const searchResults = ref(null); // null | array
    const totalResults = ref(null);

    function handleNewSearch(searchText) {
      searchText = stringToSlug(searchText);
      router.push({
        name: "Shows",
        query: { "search-text": searchText },
      });
    }

    async function handleQueryChange() {
      isLoading.value = true;
      const queryValue = route.params.id;

      if (!queryValue) {
        isLoading.value = false;
        return;
      }

      try {
        const seatGeekServiceRes = await SeatGeekService.getEvent(queryValue);
        searchResults.value = seatGeekServiceRes.data;
        console.log(searchResults.value);
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    }

    watch(() => [route.params], handleQueryChange, {
      immediate: true,
    });

    return {
      handleNewSearch,
      totalResults,
      searchResults,
      isLoading,
      stringToSlug,
    };
  },
};
</script>

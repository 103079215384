<template>
  <div v-if="isLoading">
    <h3>Loading...</h3>
  </div>

  <div v-else-if="!data"></div>

  <div v-else-if="data.meta.total === 0">No upcoming events</div>

  <div v-else>
    <h4>{{ data.events[0].venue.name }}</h4>
    <p>
      {{ data.events[0].venue.address }},
      {{ data.events[0].venue.display_location }}
    </p>
    <p>Upcoming events: {{ data.meta.total }}</p>
    <table class="table">
      <thead>
        <tr>
          <th class="event">Event</th>
          <th class="date">Date</th>
          <th class="small">Type</th>
          <th class="small">Lowest Price</th>
          <th class="small">Tickets for Sale</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in data.events" v-bind:key="result.id">
          <td>
            <router-link
              :to="{
                name: 'Events',
                params: { id: result.id },
              }"
              >{{ result.short_title }}</router-link
            >
          </td>
          <td>{{ adjustTimeCode(result.datetime_local) }}</td>
          <td>{{ returnTypeShow(result.type) }}</td>
          <td v-if="result.stats.lowest_price">
            <a :href="result.url">${{ result.stats.lowest_price }}</a>
          </td>
          <td v-else>-</td>
          <td v-if="result.stats.visible_listing_count">
            {{ result.stats.visible_listing_count }}
          </td>
          <td v-else>0</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import returnTypeShow from "@/components/functions/return_type_show.js";

// Required to format dates from UTC
dayjs.extend(utc);
export default {
  props: {
    data: {
      Type: Array,
      required: true,
    },
    isLoading: {
      Type: Boolean,
      required: true,
    },
  },
  setup() {
    function adjustTimeCode(timestamp) {
      return dayjs(timestamp).format("MMM D, YYYY");
    }
    return { adjustTimeCode, returnTypeShow };
  },
};
</script>

<style scoped>
.table {
  padding: 10px 0 0 0;
  margin: auto;
}
.table th {
  max-width: 200px;
}
th.small {
  width: 30px;
}
th.date {
  width: 100px;
}
th.event {
  max-width: 300px;
}
.table tr:first-of-type td {
  border-top: 1px solid black;
}
</style>

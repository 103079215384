<template>
  <div v-if="isLoading">
    <h3>Loading...</h3>
  </div>

  <div v-else-if="!data"></div>

  <div v-else-if="data.length === 0">No results</div>

  <div v-else>
    <table class="table">
      <thead>
        <tr>
          <th class="venue">Venue</th>
          <th class="location">Location</th>
          <th class="small">Upcoming Events</th>
          <th class="capacity">Capacity</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in data" v-bind:key="result.id">
          <td>
            <router-link
              :to="{
                name: 'Venue',
                params: { id: result.id },
              }"
              >{{ result.name }}</router-link
            >
          </td>
          <td v-if="result.country === 'US'">
            {{ result.city }}, {{ result.state }}
          </td>
          <td v-else-if="result.country === 'Canada'">
            {{ result.city }}, {{ result.state }}
          </td>
          <td v-else>{{ result.city }}, {{ result.country }}</td>
          <td class="small">{{ result.stats.event_count }}</td>
          <td class="small">{{ result.capacity }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Array,
      required: true,
    },
    isLoading: {
      Type: Boolean,
      required: false,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.table {
  padding: 10px 0 0 0;
  margin: auto;
}
.table th {
  width: 125px;
}
th.venue {
  width: auto;
}
th.location {
  width: auto;
}
th.small {
  width: 30px;
}
th.capacity {
  width: 50px;
}
th.date {
  width: 40px;
}
.table tr:first-of-type td {
  border-top: 1px solid black;
}
</style>

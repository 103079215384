export default function returnTypeShow(str) {
  if (str === "mlb") {
    return "MLB";
  } else if (str === "band") {
    return "Band";
  } else if (str == "theater") {
    return "Theater";
  } else if (str === "concert") {
    return "Concert";
  } else if (str === "sports") {
    return "Sports";
  } else if (str === "nhl") {
    return "NHL";
  } else if (str === "broadway_tickets_national") {
    return "Broadway Show";
  } else if (str === "nba") {
    return "NBA";
  } else if (str == "ncaa_basketball" || str == "ncaa_womens_basketball") {
    return "College Basketball";
  } else if (str == "ncaa_baseball") {
    return "College Baseball";
  } else if (str === "comedy") {
    return "Comedy";
  } else if (str === "classical_opera") {
    return "Opera";
  } else if (str === "mls") {
    return "MLS";
  } else if (str === "wwe") {
    return "WWE";
  } else if (str === "wrestling") {
    return "Wrestling";
  } else if (str === "fighting") {
    return "Fighting";
  } else if (str === "pga") {
    return "Golf";
  } else if (str === "nfl") {
    return "NFL";
  } else if (str === "theater_comedy") {
    return "Comedy";
  } else if (str === "boxing") {
    return "Boxing";
  } else if (
    str === "nascar" ||
    str === "nascar_cup" ||
    str === "nascar_sprintcup" ||
    str === "nascar_nationwide"
  ) {
    return "Nascar";
  } else if (str === "music_festival") {
    return "Concert - Music Festival";
  } else if (str === "boxing") {
    return "Boxing";
  } else if (str === "classical_vocal") {
    return "Classical";
  } else return str;
}

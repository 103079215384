<template>
  <div v-if="isLoading">
    <h3>Loading...</h3>
  </div>

  <div v-else-if="!data"></div>

  <div v-else>
    <table class="table">
      <thead>
        <h3>
          Event:
          {{ data.title }}
        </h3>
      </thead>
      <tbody>
        Performers:
        <tr v-for="result in data.performers" v-bind:key="result.id">
          <router-link
            :to="{
              name: 'Performer',
              params: { slug: result.slug },
            }"
            >{{ result.name }}</router-link
          >
        </tr>
        <tr>
          Venue:
          <router-link
            :to="{
              name: 'Venue',
              params: { id: data.venue.id },
            }"
            >{{ data.venue.name }}</router-link
          >
        </tr>
        <tr>
          {{
            data.venue.address
          }},
          {{
            data.venue.display_location
          }}
        </tr>
        <tr>
          Date:
          {{
            adjustTimeCode(data.datetime_local)
          }}
        </tr>
        <tr>
          Time:
          {{
            displayTime(data.datetime_local)
          }}
        </tr>
        <tr v-if="data.stats.visible_listing_count">
          Tickets Available:
          {{
            data.stats.visible_listing_count
          }}
        </tr>
        <tr v-else-if="!data.stats.visible_listing_count">
          No Tickets Available
        </tr>
        <tr v-if="data.stats.lowest_price">
          Lowest Price:
          <a :href="data.url" target="_blank"
            >${{ data.stats.lowest_price }} (${{
              data.stats.lowest_sg_base_price
            }}
            ticket + ${{
              feesCalc(data.stats.lowest_sg_base_price, data.stats.lowest_price)
            }}
            fee)</a
          >
        </tr>
        <tr v-if="data.stats.visible_listing_count > 5">
          Median Price: ${{
            data.stats.median_price
          }}
        </tr>
        <tr v-if="data.stats.visible_listing_count > 5">
          Highest Price: ${{
            data.stats.highest_price
          }}
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import computed from "vue";

// Required to format dates from UTC
dayjs.extend(utc);
export default {
  props: {
    data: {
      Type: Array,
      required: true,
    },
    isLoading: {
      Type: Boolean,
      required: true,
    },
  },
  setup() {
    function adjustTimeCode(timestamp) {
      return dayjs(timestamp).format("MMM D, YYYY");
    }
    function displayTime(timestamp) {
      return dayjs(timestamp).format("h:mm A");
    }
    function feesCalc(noFees, withFees) {
      const result = withFees - noFees;
      return result;
    }
    return { adjustTimeCode, displayTime, feesCalc };
  },
};
</script>

<style scoped>
.table {
  padding: 10px 0 0 0;
  margin: auto;
}
.table tr:first-of-type td {
  border-top: 1px solid black;
}
</style>

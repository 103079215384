<template>
  <h3>Search for venues</h3>
  <VenuesContainer />
</template>

<script>
import VenuesContainer from "@/containers/VenuesContainer";

export default {
  name: "Venues",
  components: {
    VenuesContainer,
  },
};
</script>

import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Shows from "@/views/Shows.vue";
import Venues from "@/views/Venues.vue";
import NearMe from "@/views/NearMe.vue";
import About from "@/views/About.vue";
import Performer from "@/views/Performer.vue";
import Venue from "@/views/Venue.vue";
import Events from "@/views/Events.vue";
// import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/shows",
    name: "Shows",
    component: Shows,
  },
  {
    path: "/venues",
    name: "Venues",
    component: Venues,
  },
  {
    path: "/venues/:id",
    name: "Venue",
    component: Venue,
  },
  {
    path: "/nearme",
    name: "Near Me",
    component: NearMe,
  },
  {
    path: "/performer/:slug",
    name: "Performer",
    component: Performer,
  },
  {
    path: "/events/:id",
    name: "Events",
    component: Events,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// trackRouter(router);

export default router;

<template>
  <h3>Search for shows here</h3>
  <EventContainer />
</template>

<script>
import EventContainer from "@/containers/EventContainer";

export default {
  name: "Events",
  components: {
    EventContainer,
  },
};
</script>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase";
import store from "./store";
// import VueGtag from "vue-gtag-next";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAy5BMa-cYkTGCs5aH-UNhsEu330YHfJB0",
  authDomain: "rme-list.firebaseapp.com",
  projectId: "rme-list",
  storageBucket: "rme-list.appspot.com",
  messagingSenderId: "221497306099",
  appId: "1:221497306099:web:e6d72b8affb4eb11610086",
  measurementId: "G-BDX39J1L35",
};

firebase.initializeApp(firebaseConfig);

createApp(App)
  .use(store)
  .use(router)
  // .use(VueGtag, {
  //   property: {
  //     id: "267561273",
  //     params: {},
  //   },
  // })
  .mount("#app");

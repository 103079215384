import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.seatgeek.com/2/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const per_page = "&per_page=";
const pagination_page = "&page=";
const client_id = "&client_id=NDY2MzU4NnwxNDg0ODc3NjM5";
const aid = "&aid=12452";
// const score_desc = "&sort=score.desc";

export default {
  getEvents(search, perPage, page) {
    return apiClient.get(
      "events?performers.slug=" +
        search +
        client_id +
        per_page +
        perPage +
        pagination_page +
        page +
        "&aid=12452"
    );
  },
  getEvent(id) {
    return apiClient.get("events/" + id + "?" + client_id + aid);
  },
  getShows(search, perPage, page) {
    return apiClient.get(
      "performers?q=" +
        search +
        client_id +
        per_page +
        perPage +
        pagination_page +
        page +
        aid
    );
  },
  getVenue(id, perPage, page) {
    return apiClient.get(
      "events?venue.id=" +
        id +
        client_id +
        per_page +
        perPage +
        pagination_page +
        page +
        aid
    );
  },
  getVenues(search, perPage, page) {
    return apiClient.get(
      "venues?q=" +
        search +
        client_id +
        per_page +
        perPage +
        pagination_page +
        page +
        aid
    );
  },
  getEventsNearMe(perPage, page) {
    return apiClient.get(
      "events?geoip=true&range=75mi&listing_count.gt=0" +
        client_id +
        per_page +
        perPage +
        pagination_page +
        page +
        aid
    );
  },
};
// getShow(id) {
//   return apiClient.get("/events/" + id);
// }

<template>
  <SearchBar @new-search="handleNewSearch" />
  <div v-if="totalResults">
    <p>Total results: {{ totalResults }}</p>
  </div>
  <p>
    <button v-if="showPrev" @click="prev">Prev</button
    ><button v-if="showNext" @click="next">Next</button>
  </p>
  <TableVenuesResults :data="searchResults" :isLoading="isLoading" />
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import TableVenuesResults from "@/components/TableVenuesResults";
import router from "@/router";
import { watch } from "@vue/runtime-core";
import { ref } from "vue";
import { useRoute } from "vue-router";
import SeatGeekService from "@/services/SeatGeekService";
import stringToSlug from "@/components/functions/string_to_slug.js";
import {
  moreResults,
  fewerResults,
  paginationCalc,
} from "@/components/functions/pagination.js";

export default {
  name: "VenuesContainer",
  components: {
    SearchBar,
    TableVenuesResults,
  },
  setup() {
    const route = useRoute();
    const isLoading = ref(false);
    const searchResults = ref(null); // null | array
    const totalResults = ref(null);
    const resultsPerPage = ref(50);
    const pageCalled = ref(1);
    const showNext = ref(false);
    const showPrev = ref(false);
    const paginationLimit = ref(1);
    const currentPage = ref(1);

    function handleNewSearch(searchText) {
      searchText = stringToSlug(searchText);
      router.push({
        query: { "search-text": searchText },
      });
    }

    function next() {
      pageCalled.value++;
      currentPage.value++;
    }
    function prev() {
      pageCalled.value--;
      currentPage.value--;
    }

    async function handleQueryChange() {
      isLoading.value = true;
      const queryValue = route.query["search-text"];

      if (!queryValue) {
        isLoading.value = false;
        return;
      }

      try {
        const seatGeekServiceRes = await SeatGeekService.getVenues(
          queryValue,
          resultsPerPage.value,
          pageCalled.value
        );
        totalResults.value = seatGeekServiceRes.data.meta.total;
        searchResults.value = seatGeekServiceRes.data.venues;
        let paginationLimit = paginationCalc(
          totalResults.value,
          resultsPerPage.value
        );
        showNext.value = moreResults(currentPage.value, paginationLimit.value);
        showPrev.value = fewerResults(currentPage.value, paginationLimit.value);
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    }

    watch(() => [route.query, pageCalled.value], handleQueryChange, {
      immediate: true,
    });

    return {
      handleNewSearch,
      totalResults,
      searchResults,
      isLoading,
      stringToSlug,
      next,
      prev,
      showNext,
      showPrev,
      paginationCalc,
      paginationLimit,
      currentPage,
      moreResults,
      fewerResults,
    };
  },
};
</script>

import { computed } from "vue";

export function moreResults(currentPage, limit) {
  if (currentPage === limit) {
    return false;
  } else if (currentPage < limit) {
    return true;
  }
}

export function fewerResults(currentPage, limit) {
  if (currentPage === 1) {
    return false;
  } else if (currentPage <= limit) {
    return true;
  }
}

export function paginationCalc(results, resultsPerPage) {
  return computed(() => Math.ceil(results / resultsPerPage));
}

export default { moreResults, fewerResults, paginationCalc };

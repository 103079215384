<template>
  <h3>Search for venues</h3>
  <VenueContainer />
</template>

<script>
import VenueContainer from "@/containers/VenueContainer";

export default {
  name: "Venue",
  components: {
    VenueContainer,
  },
};
</script>

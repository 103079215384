<template>
  <SearchBar @new-search="handleNewSearch" />
  <p v-if="totalResults">Total results: {{ totalResults }}</p>
  <p>
    <button v-if="showPrev" @click="prev">Prev</button
    ><button v-if="showNext" @click="next">Next</button>
  </p>
  <TableNearMeResults :data="searchResults" :isLoading="isLoading" />
  <p>
    <button v-if="showPrev" @click="prev">Prev</button
    ><button v-if="showNext" @click="next">Next</button>
  </p>
</template>

<script>
import TableNearMeResults from "@/components/TableNearMeResults.vue";
import SearchBar from "@/components/SearchBar.vue";
import { watch } from "@vue/runtime-core";
import { ref } from "vue";
import SeatGeekService from "@/services/SeatGeekService";
import router from "@/router";
import { useRoute } from "vue-router";
import stringToSlug from "@/components/functions/string_to_slug.js";
import {
  moreResults,
  fewerResults,
  paginationCalc,
} from "@/components/functions/pagination.js";

export default {
  name: "NearMeContainer",
  components: {
    SearchBar,
    TableNearMeResults,
  },
  setup() {
    const route = useRoute();
    const isLoading = ref(false);
    const searchResults = ref(null); // null | array
    const totalResults = ref(null);
    const resultsPerPage = ref(50);
    const pageCalled = ref(1);
    const showNext = ref(false);
    const showPrev = ref(false);
    const paginationLimit = ref(1);
    const currentPage = ref(1);

    function handleNewSearch(searchText) {
      searchText = stringToSlug(searchText);
      router.push({
        name: "Shows",
        query: { "search-text": searchText },
      });
    }

    function next() {
      pageCalled.value++;
      currentPage.value++;
    }
    function prev() {
      pageCalled.value--;
      currentPage.value--;
    }

    async function loadPage() {
      isLoading.value = true;
      try {
        const seatGeekServiceRes = await SeatGeekService.getEventsNearMe(
          resultsPerPage.value,
          pageCalled.value
        );
        totalResults.value = seatGeekServiceRes.data.meta.total;
        searchResults.value = seatGeekServiceRes.data.events;
        console.log(searchResults.value);
        let paginationLimit = paginationCalc(
          totalResults.value,
          resultsPerPage.value
        );
        showNext.value = moreResults(currentPage.value, paginationLimit.value);
        showPrev.value = fewerResults(currentPage.value, paginationLimit.value);
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    }

    watch(() => [route.query, pageCalled.value], loadPage, {
      immediate: true,
    });

    return {
      handleNewSearch,
      resultsPerPage,
      totalResults,
      searchResults,
      isLoading,
      stringToSlug,
      next,
      prev,
      showNext,
      showPrev,
      paginationCalc,
      paginationLimit,
      currentPage,
      moreResults,
      fewerResults,
    };
  },
};
</script>

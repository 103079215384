<template>
  <h3>Events Near Me</h3>
  <h4>Search for other live shows</h4>
  <NearMeContainer />
</template>

<script>
import NearMeContainer from "@/containers/NearMeContainer";

export default {
  name: "NearMe",
  components: {
    NearMeContainer,
  },
};
</script>

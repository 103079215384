<template>
  <div>
    <form class="search-bar" @submit.prevent="handleSubmit">
      <label for="search-bar"></label>
      <input
        id="search-bar"
        placeholder="Enter Search Here"
        v-model="searchText"
      />
      <button>Search</button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "SearchBar",
  setup(_, context) {
    const searchText = ref("");

    function handleSubmit() {
      // searchText.value = stringToSlug(searchText.value);
      context.emit("new-search", searchText.value);
      searchText.value = "";
    }

    return {
      searchText,
      handleSubmit,
    };
  },
};
</script>

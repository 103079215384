<template>
  <h3>Search for live shows</h3>
  <PerformerContainer />
</template>

<script>
import PerformerContainer from "@/containers/PerformerContainer";

export default {
  name: "Performer",
  components: {
    PerformerContainer,
  },
};
</script>

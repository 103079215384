<template>
  <Shows />
</template>

<script>
import Shows from "@/views/Shows.vue";

export default {
  name: "Home",
  components: {
    Shows,
  },
};
</script>
